import './App.css'
import {useEffect, useState} from 'react';
import {LiveKitRoom, VideoConference} from '@livekit/components-react';
import '@livekit/components-styles';
import { faker } from '@faker-js/faker';

function App() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    fetch("https://contrast.resonious.workers.dev/join", {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        identity: makeid(5),
        name: faker.person.firstName(),
        room: "theroom",
      }),
    }).then(x => x.text())
      .then(response => {
        console.log("token:", response);
        setToken(response);
      });
  }, []);

  return (
    <div className="App">
      <LiveKitRoom token={token} serverUrl="wss://baillie-dev-j75tlg5q.livekit.cloud">
        <VideoConference/>
      </LiveKitRoom>
    </div>
  );
}

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export default App;
